body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*======INPUT======*/
.registerMerchan .ant-input-affix-wrapper,
.registerMerchan .tgllahir,
.registerMerchan .ant-select-selector {
  border-radius: 7px !important;
  border: 1px solid #aaaaaa !important;
}

.ant-input-affix-wrapper,
.tgllahir,
.ant-select-selector {
  border-radius: 7px !important;
  border: 1px solid #aaaaaa !important;
}

.ant-form-item{
  margin-bottom: 9px !important;
}

.ant-btn-round{
  border-radius: 7px !important;
}

#register_birthDate {
  width: 100%;
}
/*======INPUT======*/

.ant-row {
  background: transparent;
}

.topUp {
  background: #0081a0;
  padding: 15px;
  margin: 15px;
  border-radius: 21px !important;
  border: none !important;
}

.topUp .ant-card-body {
  padding: 11px;
}

.topUp .ant-card-meta-title {
  font-size: 12px !important;
  font-weight: 900 !important;
}

.topUp .ant-card-meta-description {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.login-drawer .ant-drawer-body,
.login-drawer .ant-layout {
  background: #ffffff;
  padding: 0;
}

.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.kategori .ant-card-meta-title{
  text-align: center;
}

.buttonrow button{
  margin:3px;
}

.profile .ant-list-item-meta-title{
  font-size: 12px !important;
  font-weight: 500;
}